body {
  font-family: "Futura PT Book";
  /* background-color: #666666; */
  color: #343c4d;
  background-image: url("./icons/background.svg");
  background-size: cover;
  /* background-position: center; */
  /* background-repeat: no-repeat; */
  overflow: hidden;
  box-sizing: border-box;
}

.auth-theme {
  background-image: url("./icons/background-auth.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

thead {
  position: sticky;
  top: 0;
}

h2 {
  font-family: "Futura PT Heavy";
}

.nav-link {
  padding: 13px 3px;
}

.nav-link-offcanvas {
  padding: 4px;
  text-decoration: none;
  color: #343c4d;
}

/* Стили для font */

.ff-heavy {
  font-family: "Futura PT Heavy";
}

.ff-heavy-link {
  font-family: "Futura PT Heavy";
  color: #cb9722;
}

.ff-book {
  font-family: "Futura PT Book";
}

.ff-demi {
  font-family: "Futura PT Demi";
}

.ff-medium {
  font-family: "Futura PT Medium";
}

.ff-heavy-22 {
  font-family: "Futura PT Heavy";
  font-size: 22px;
  color: #cb9722;
}

.ff-demi-22 {
  font-family: "Futura PT Demi";
  font-size: 22px;
}

.ff-demi-16 {
  font-family: "Futura PT Demi";
  font-size: 16px;
}

.modal-content {
  border-radius: 0;
}

/* Кнопка одобрения */

.btn-approve {
  background: linear-gradient(
    119.84deg,
    #ad7400 10.16%,
    #baa61d 43.82%,
    #a77000 77.23%
  );
  border: 0;
  border-radius: 0;
  font-family: "Futura PT Demi";
  filter: hue-rotate(123deg);
  height: 50px;
  min-width: 50px;
}

/* Кнопка отклонения */

.btn-reject {
  background: linear-gradient(
    119.84deg,
    #ad7400 10.16%,
    #baa61d 43.82%,
    #a77000 77.23%
  );
  border: 0;
  border-radius: 0;
  font-family: "Futura PT Demi";
  filter: hue-rotate(313deg);
  height: 50px;
  min-width: 50px;
}

/* Нейтральная кнопка/кнопка чата */

.btn-contact {
  background: linear-gradient(
    119.84deg,
    #ad7400 10.16%,
    #baa61d 43.82%,
    #a77000 77.23%
  );
  border: 0;
  border-radius: 0;
  font-family: "Futura PT Demi";
  filter: grayscale(1);
  height: 50px;
  min-width: 50px;
}

.btn-approve:hover,
.btn-approve:focus,
.btn-approve:active,
.btn-reject:hover,
.btn-reject:focus,
.btn-reject:active,
.btn-contact:hover,
.btn-contact:focus,
.btn-contact:active {
  background-color: inherit;
}

/* Инпут/лейбл/кнопка для фильтров */

.label-filter {
  font-family: "Futura PT Demi";
  font-size: 15px;
  color: white;
}

.input-filter {
  min-height: 40px;
  border-radius: 0;
  font-family: "Futura PT Heavy";
  font-size: 16px;
  color: white;
  background-color: #2d1c04;
  border-color: #2d1c04;
  font-size: 16px;
  padding-left: 5px;
  padding-right: 5px;
}

.input-filter:focus {
  min-height: 40px;
  border-radius: 0;
  font-family: "Futura PT Heavy";
  font-size: 16px;
  color: white;
  background-color: #2d1c04;
  border-color: #2d1c04;
  box-shadow: none;
  outline: none;
}

.input-filter:-webkit-autofill,
.input-filter:-webkit-autofill:hover,
.input-filter:-webkit-autofill:focus,
.input-filter:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #2d1c04 inset !important;
  -webkit-text-fill-color: white !important;
  caret-color: white;
  font-family: "Futura PT Heavy";
  font-size: 16px;
}

.btn-filter {
  background-color: rgb(255, 255, 255, 0.1);
  height: 42px;
  width: 42px;
  border-radius: 0;
}

.btn-filter:hover {
  background-color: rgb(255, 255, 255, 0.1);
}

/* Кастомный toggle switch */

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: rgb(203, 151, 34, 0.4);
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}

.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 3px;
  width: 15px;
  height: 15px;
  background-color: #cb9722;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(21px);
  background-color: #cb9722;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: #2d1c04;
}

/* Инпут/лейбл для форм объектов */

.label-object {
  font-family: "Futura PT Demi";
  font-size: 15px;
}

.input-object {
  height: 42px;
  border-radius: 0;
  outline: none;
  border-color: transparent;
  color: #343c4d;
  opacity: 0.7;
  background-color: rgb(7, 3, 34, 0.05);
}

.input-object::placeholder {
  color: #343c4d;
  opacity: 0.7;
  font-family: "Futura PT Demi";
  font-size: 16px;
}

.input-object:disabled {
  color: #c2c0c5;
}

.input-object:hover,
.input-object:focus {
  outline-offset: 0;
  box-shadow: none;
  border: 2px solid rgb(203, 151, 34, 0.4);
  border-radius: 0;
  background-color: rgb(7, 3, 34, 0.05);
  color: #343c4d;
  opacity: 1;
}

/* .select__dropdown-indicator {
  background-image: url("./icons/drop-custom.svg");
  background-color: rgb(f, f, f, 0);
} */

/* .css-15kajzr-control,
.css-15kajzr-control:focus {
  box-shadow: none;
}

.css-14w3poz-control:focus {
  box-shadow: none;
  border-color: rgb(203, 151, 34, 0.4);
} */

/* Стили для объектов free/soldout */

.sale-object {
  height: 40px;
  width: 30%;
  z-index: 2;
}

.soldout {
  background-color: rgb(255, 40, 40, 0.7);
}

.free {
  background-color: rgb(203, 151, 34, 0.7);
}

/* Ночная яндекс карта */

[class*="ground-pane"] {
  filter: hue-rotate(180deg) brightness(0.9) invert(1) !important;
  -ms-filter: hue-rotate(180deg) brightness(0.9) invert(1) !important;
  -webkit-filter: hue-rotate(180deg) brightness(0.9) invert(1) !important;
  -moz-filter: hue-rotate(180deg) brightness(0.9) invert(1) !important;
  -o-filter: hue-rotate(180deg) brightness(0.9) invert(1) !important;
}

/* Касточная иконка drop */

.drop-custom {
  --bs-form-select-bg-img: url("./icons/drop-custom.svg");
}

/* Кастомный checkbox */

.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox-label {
  font-size: 11px;
}

.custom-checkbox + .custom-checkbox-label {
  display: inline-flex;
  align-items: flex-start;
  user-select: none;
}

.custom-checkbox + .custom-checkbox-label::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 0;
  margin-right: 8px;
  margin-top: 1px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox:checked + label::before {
  border-color: #adb5bd;
  background-color: #e9ecef;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="%23cb9722"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>');
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255,255, 255, 0.75%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

/* Кнопка базовая */

.btn-base {
  background: linear-gradient(
    119.84deg,
    #ad7400 10.16%,
    #baa61d 43.82%,
    #a77000 77.23%
  );
  border: 0;
  border-radius: 0;
  font-family: "Futura PT Heavy";
  font-size: 15px;
  padding: 0px 20px;
}

/* Кнопка меню */

.btn-menu {
  background: transparent;
  border-color: #cb9722;
  border-radius: 0;
  color: #cb9722;
  height: 50px;
  text-decoration: none;
  font-family: "Futura PT Book";
  padding: 0px 10px;
  min-width: 80px;
}

.btn-menu:hover,
.btn-menu:focus,
.btn-menu:active {
  border-color: #cb9722;
  border-radius: 0;
  color: #cb9722;
}

/* Кнопка меню активная */

.btn-menu-active {
  background-color: rgba(203, 151, 34, 0.2);
  border: 0;
  border-radius: 0;
  color: #cb9722;
  font-family: "Futura PT Heavy";
  height: 50px;
  text-decoration: none;
  padding: 0px 10px;
  min-width: 80px;
}

.btn-menu-active:hover,
.btn-menu-active:active,
.btn-menu-active:focus {
  color: #cb9722;
  background-color: rgba(203, 151, 34, 0.2);
}

/* Кнопка загрузки */

.btn-load {
  background-color: white;
  border-color: #cb9722;
  border-radius: 0;
  height: 48px;
  font-family: "Futura PT Demi";
}

.btn-load:hover {
  background-color: white;
  border-color: #cb9722;
}

/* Формы регистрации/входа */

.input-auth-error,
.input-auth-error:focus,
.input-auth-error:active,
.input-auth-error:hover {
  border: 2px solid #fc4545;
  color: #fc4545;
  min-height: 48px;
  outline: none;
  border-radius: 0;
  font-family: "Futura PT Demi";
  font-size: 16px;
  box-shadow: none;
  outline: 0;
}

.login-form {
  width: 720px;
}

.link-auth {
  font-family: "Futura PT Demi";
  font-size: 14px;
  color: #cb9722;
}

.label-auth {
  top: -9px;
  left: 12px;
  line-height: 1;
  font-size: 12px;
  background-color: white;
  color: rgb(52, 60, 77, 0.7);
  font-family: "Futura PT Demi";
}

.btn-auth {
  background: linear-gradient(
    119.84deg,
    #ad7400 10.16%,
    #baa61d 43.82%,
    #a77000 77.23%
  );
  border: 0;
  border-radius: 0;
  font-family: "Futura PT Demi";
  font-size: 18px;
  padding: 0px 20px;
}

.input-auth {
  min-height: 48px;
  outline: none;
  border-color: rgb(222, 226, 230);
  border-radius: 0;
  color: #343c4d;
  font-family: "Futura PT Demi";
  font-size: 16px;
}

.input-auth::placeholder {
  color: #343c4d;
  opacity: 0.4;
  font-family: "Futura PT Demi";
  font-size: 16px;
}

.input-auth:focus,
.input-auth:active,
.input-auth:focus {
  outline-offset: 0;
  box-shadow: none;
  border: 2px solid rgb(203, 151, 34, 0.4);
}

.input-auth:-webkit-autofill,
.input-auth:-webkit-autofill:hover,
.input-auth:-webkit-autofill:focus,
.input-auth:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* Кастомные td для таблиц */

.td-base {
  text-wrap: nowrap;
}

.td-profit {
  color: #cb9722;
  background-color: rgba(252, 69, 69, 0.1);
  margin: 2px 0;
  padding: 6px 15px;
}

.td-status {
  background-color: rgba(252, 69, 69, 0.1);
  margin: 2px 0;
  padding: 6px 4px;
}

.td-status-notbg {
  margin: 2px 0;
  padding: 6px 4px;
}

/* Кастомные карточки для списка карточек */

.card-profit {
  background-color: rgba(252, 69, 69, 0.1);
}

.card-chat {
  font-size: 18px;
  min-width: 320px;
  color: #343c4d;
}

/* Скролл таблица/чат */

.scroll-table {
  overflow: auto;
  max-height: calc(100vh - 270px);
}

.chatForm::-webkit-scrollbar,
.chatFormAdaptive::-webkit-scrollbar,
.scroll-table::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.chatForm::-webkit-scrollbar-track-piece,
.chatFormAdaptive::-webkit-scrollbar-track-piece,
.scroll-table::-webkit-scrollbar-track-piece {
  background: transparent;
  -webkit-border-radius: 6px;
}

.chatForm::-webkit-scrollbar-thumb:vertical,
.chatFormAdaptive::-webkit-scrollbar-thumb:vertical,
.scroll-table::-webkit-scrollbar-thumb:vertical {
  height: 5px;
  background-color: #343c4d;
}
.chatForm::-webkit-scrollbar-thumb:horizontal,
.chatFormAdaptive::-webkit-scrollbar-thumb:horizontal,
.scroll-table::-webkit-scrollbar-thumb:horizontal {
  width: 5px;
  background-color: #343c4d;
}

.chatForm::-webkit-scrollbar-corner,
.chatFormAdaptive::-webkit-scrollbar-corner,
.scroll-table::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* Скролл чат фокус */

.chatFormFocused::-webkit-scrollbar,
.chatFormFocusedAdaptive::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.chatFormFocused::-webkit-scrollbar-track-piece,
.chatFormFocusedAdaptive::-webkit-scrollbar-track-piece {
  background-color: transparent;
  -webkit-border-radius: 6px;
}
.chatFormFocused::-webkit-scrollbar-thumb:vertical,
.chatFormFocusedAdaptive::-webkit-scrollbar-thumb:vertical {
  height: 5px;
  background-color: #343c4d;
}
.chatFormFocused::-webkit-scrollbar-thumb:horizontal,
.chatFormFocusedAdaptive::-webkit-scrollbar-thumb:horizontal {
  width: 5px;
  background-color: #343c4d;
}

.chatFormFocused::-webkit-scrollbar-corner,
.chatFormFocusedAdaptive::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* Скролл базовый */

.container-scroll {
  overflow: auto;
  height: calc(100vh - 70px);
}

.container-scroll::-webkit-scrollbar {
  width: 11px;
  height: 6px;
}
.container-scroll::-webkit-scrollbar-track-piece {
  background-color: rgb(255, 255, 255, 0.15);
  -webkit-border-radius: 0;
}
.container-scroll::-webkit-scrollbar-thumb:vertical {
  height: 6px;
  background-color: #cb9722;
}
.container-scroll::-webkit-scrollbar-thumb:horizontal {
  width: 6px;
  background-color: #cb9722;
}

.container-scroll::-webkit-scrollbar-corner {
  background-color: transparent;
}

.message-block:hover {
  background-color: rgb(203, 151, 34, 0.1);
  transition: 0.1s linear;
  cursor: pointer;
}

/* chatForm */
.inputChatForm {
  height: 50px;
  resize: none;
  border: 2px solid rgba(7, 3, 34, 0.05);
  color: #343c4d;
  font-family: "Futura PT Demi";
  font-size: 16px;
}

.inputChatForm:focus {
  box-shadow: none;
  border: 2px solid rgba(7, 3, 34, 0.05);
  height: 200px;
  resize: none;
}

.inputChatForm::placeholder {
  color: #343c4d;
  opacity: 0.7;
  font-family: "Futura PT Demi";
  font-size: 16px;
}

.chatForm {
  height: calc(100vh - 225px);
}

.chatFormFocused {
  height: calc(100vh - 375px);
}

/* chatForm adaptive*/

.chatFormAdaptive {
  height: calc(100vh - 293px);
}

.chatFormFocusedAdaptive {
  height: calc(100vh - 393px);
}

.inputChatFormAdaptive {
  height: 50px;
  resize: none;
}

.inputChatFormAdaptive:focus {
  height: 150px;
  resize: none;
}

.container-list {
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(100vh - 90px);
}

.container-list-mc-card {
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
}

.wrapper {
  width: 720px;
  max-width: calc(100vw - 40px);
}

.wrapper-mc {
  width: 1200px;
  max-width: calc(100vw - 100px);
}

.container-form {
  min-height: calc(100vh - 70px);
}

/* Ссылка кнопка меню */

.link-menu,
.link-menu:hover,
.link-menu:visited,
.link-menu:focus,
.link-menu:active {
  text-decoration: none;
  outline: none;
  color: #cb9722;
  padding: 13px 20px;
}

.form {
  max-width: 560px;
}

.form__data {
  max-width: 380px;
}

.modal-profile-mc {
  max-width: 89%;
}

.modal-edit {
  width: 50%;
  max-width: 50%;
  height: 60%;
}

/* Уведомления */

.message-icon {
  background-color: #cb9722;
  border-radius: 2px;
  margin-right: 5px;
  padding: 0 8px;
  color: white;
  font-family: "Futura PT Demi";
}

.message-info {
  color: #cb9722;
  font-family: "Futura PT Heavy";
  font-size: 18px;
}

.message-text {
  width: 85%;
}

.message-user {
  word-wrap: break-word;
  white-space: pre-line;
  margin-bottom: 5px;
}

.notification-user {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
}

@media screen and (max-width: 1119px) {
  .navbar-classic {
    display: none;
  }
}

@media screen and (min-width: 1120px) {
  .navbar-offcanvas {
    display: none;
  }
}

/* @media screen and (max-width: 1099px) {
  .navbar-classic {
    display: none;
  }
} */

@media screen and (min-width: 1100px) {
  /* .navbar-offcanvas {
    display: none;
  } */

  .modal-profile {
    width: 500px;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .profile-object-classic-adaptive {
    display: none;
  }

  .profile-object-classic {
    display: none;
  }

  .mc-signup-form {
    display: none;
  }

  .mc-signup-form-adaptive {
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .chatFormMobile {
    display: none;
  }

  .mc-signup-form-adaptive {
    display: none;
  }

  .mc-signup-form {
    display: flex;
  }
}

@media screen and (max-width: 1199px) and (min-width: 768px) {
  .profile-object-classic {
    display: none;
  }

  .profile-object-classic-mobile {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .profile-object-classic-adaptive {
    display: none;
  }

  .profile-object-classic-mobile {
    display: none;
  }
}

@media screen and (min-width: 1400px) {
  .wrapper-cards {
    width: 1350px;
  }
}

@media screen and (max-width: 1299px) {
  .button-contact {
    display: none;
  }

  .button-contact-compact {
    display: flex;
  }

  .modal-edit {
    width: 80%;
    max-width: 80%;
  }

  .message-text {
    width: 85%;
  }
}

@media screen and (min-width: 1300px) {
  .button-contact {
    display: flex;
  }

  .button-contact-compact {
    display: none;
  }

  .modal-profile-mc {
    max-width: 70%;
  }
}

@media screen and (max-width: 810px) {
  .message-text {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .profile-user {
    display: none;
  }

  .modal-profile-mc {
    max-width: 95%;
  }

  .modal-edit {
    width: 90%;
    max-width: 90%;
  }

  .message-text {
    width: 70%;
  }
}

@media screen and (max-width: 1189px) {
  .button-contact-mc {
    display: none;
  }
}

@media screen and (min-width: 1190px) {
  .button-contact-compact-mc {
    display: none;
  }
}

@media screen and (max-width: 1099px) {
  .form-filter {
    display: none;
  }

  .chatFormClassic {
    display: none;
  }
}

@media screen and (min-width: 1100px) {
  .form-filter-compact {
    display: none;
  }

  .chatFormMobile {
    display: none;
  }
}

@font-face {
  font-family: "Futura PT Heavy";
  src: url("./fonts/FuturaPT-Heavy.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "Futura PT Book";
  src: url("./fonts/FuturaPT-Book.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Futura PT Medium";
  src: url("./fonts/FuturaPT-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Futura PT Demi";
  src: url("./fonts/FuturaPT-Demi.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
